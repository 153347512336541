import {put, call, takeEvery, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {AgoraChannel, CallType, OutgoingCallType} from "@sense-os/goalie-js";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {ChatRoomAction} from "../../chat/redux/ChatRoomAction";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {getSelectedCallType, isVideoCall} from "../../startCallScreen/redux/startCallScreenSelector";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

import {agoraSDK} from "../agoraSDK/agoraSdk";
import {agoraActions} from "../redux/agoraActions";
import {AgoraWindowAction} from "../redux/types";

const log = createLogger("agoraChannelSaga", SentryTags.AgoraMeeting);

function* handleCreateChannel(action: ActionType<typeof agoraActions.createAgoraChannel.request>) {
	const {channelName, patientHashId, activity} = action.payload;
	try {
		const token: string = yield call(getSessionId);
		const authUser: AuthUser = yield select(getAuthUser);
		const selectedContactId: number = yield select(getSelectedContactId);
		const outgoingCallType: OutgoingCallType = yield select(getSelectedCallType);
		const isVideo: boolean = yield select(isVideoCall);
		const callType: CallType = isVideo ? CallType.Video : CallType.Audio;

		// Create channel and rtc token
		const response: AgoraChannel = yield apiCallSaga(agoraSDK.createChannel, token, {
			name: channelName,
			patient: patientHashId,
			activity,
		});
		yield put(agoraActions.createAgoraChannel.success({agoraChannel: response}));

		// Send invitation through chat
		const messageText: string = loc.formatMessage(strTranslation.AGORA.notification.invitation.text, {
			name: authUser.fullName,
		});
		yield put(
			ChatRoomAction.sendTextMessage(selectedContactId, messageText, {
				meetingInfo: {
					id: response.id,
					name: response.name,
					callType: callType,
					outgoingCallType: outgoingCallType,
				},
			}),
		);

		yield put(
			agoraActions.openAgoraWindow.request({
				windowAction: AgoraWindowAction.JOIN,
				channelId: response.id,
				callType: callType,
				channelCreatorId: authUser.id,
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Failed to create Agora channel."});
		yield put(agoraActions.createAgoraChannel.failure(err));
	}
}

function* handleGetChannel(action: ActionType<typeof agoraActions.getAgoraChannel.request>) {
	const {channelId} = action.payload;
	try {
		const token: string = yield call(getSessionId);
		const response: AgoraChannel = yield apiCallSaga(agoraSDK.getChannelById, token, channelId);
		yield put(agoraActions.getAgoraChannel.success({agoraChannel: response}));
	} catch (err) {
		log.captureException(err, {message: "Failed to get Agora channel."});
		yield put(agoraActions.getAgoraChannel.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(agoraActions.createAgoraChannel.request), handleCreateChannel);
	yield takeEvery(getType(agoraActions.getAgoraChannel.request), handleGetChannel);
}
